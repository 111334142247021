import './BngCockpitView.css';

import React from 'react';
import PropTypes from 'prop-types';

import LoadingCenter from 'components/ui/loading/LoadingCenter';
import Api from 'components/Api';
import CockpitBody from 'components/ui/cockpit/CockpitBody';
import IndexPage from 'components/ui/cockpit/IndexPage';
import { TYPES } from 'components/ui/cockpit/Cockpit';
import Kpi from 'components/ui/kpi/Kpi';
import Utils from 'components/Utils';
import BiSource from 'components/ui/map/BiSource';
import CockpitAnalysisRender from 'components/bng/pages/cockpit/renders/CockpitAnalysisRender';
import BngCockpitNavigator from 'components/bng/pages/cockpit/duplicated/BngCockpitNavigator';
import BngCockpitTabs from 'components/bng/pages/cockpit/duplicated/BngCockpitTabs';
import BngCockpitButtons from 'components/bng/pages/cockpit/duplicated/BngCockpitButtons';
import DeepEqualComponent from 'components/DeepEqualComponent';
import CockpitDashboardRender from 'components/bng/pages/cockpit/renders/CockpitDashboardRender';
import ContextEnhancer from 'components/ContextEnhancer';
import BngEmpty from 'components/bng/ui/BngEmpty';
import StructureNotLoadedPage from 'components/bng/pages/errors/StructureNotLoadedPage';
import UiMsg from 'components/ui/UiMsg';
import FilterService from 'components/filter/FilterService';
import BigTable from 'components/bng/pages/bigTable/BigTable';
import bimEventBus from 'BimEventBus';
import { ON_OPEN_OBJECT_EVENT } from 'components/ui/dashboard/components/IconText';
import Breadcrumb from 'components/ui/breadcrumb/Breadcrumb';
import HtmlPage from 'components/ui/cockpit/HtmlPage';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import DashGridEditor from 'components/ui/dashboard/components/DashGridEditor';

const CockpitHeader = ({ children }) => (
  <div id="cockpit-nav-header">
    <div id="cockpit-header" className="iceFrm cockpit-nav-menu-form">
      {children}
    </div>
  </div>
);

class BngCockpitView extends DeepEqualComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    loading: true,
    cockpits: null,
    cockpit: null,
    currentPanel: null,
    panelStructureNotLoaded: false,
    $cockpitPanel: null,
    globalFilters: {
      active: [],
      inactive: [],
      isFiltered: false,
    },
    navigationStack: [],
  };

  async componentDidMount() {
    bimEventBus.on(ON_OPEN_OBJECT_EVENT, this.onOpenObject);
    try {
      const cockpits = await this.fetchCockpits();
      if (!_.isEmpty(cockpits)) {
        const cockpitId = _.parseInt(Utils.History.currentUrlSearchParams().get('cockpitId') || '-1');
        const cockpit = cockpits.find((c) => c.id === cockpitId);
        await this.loadCockpit(cockpit || cockpits[0]);
      }
      this.setState({ cockpits });
    } finally {
      this.setState({ loading: false });
    }
    document.querySelector('#bim-react')?.classList.add('cockpit-enabled');
  }

  componentWillUnmount() {
    bimEventBus.off(ON_OPEN_OBJECT_EVENT, this.onOpenObject);
    document.querySelector('#bim-react')?.classList.remove('cockpit-enabled');
  }

  // Dashboard icon navigation handler
  onOpenObject = async ({ path, dashboardFilters, filters, forceFilters }) => {
    this.setState({ loading: true });
    try {
      let navigationStack = this.state.navigationStack.slice();
      let currentPanel;

      const idx = navigationStack.findIndex((stackedPanel) => stackedPanel.path === path);
      // If path already on breadcrumb then navigate to it
      // See: https://github.com/sol7/bi-machine/issues/7607
      if (idx !== -1) {
        currentPanel = navigationStack[idx];
        navigationStack = navigationStack.slice(0, idx);
      } else {
        const panelToStack = {
          ...this.state.currentPanel,
          filters: dashboardFilters,
        };
        navigationStack.push(panelToStack);

        const caption = await Api.Bng.getPathCaption(path);
        currentPanel = _.cloneDeep(this.state.currentPanel);
        currentPanel.name = caption;
        currentPanel.path = path;
        currentPanel.icon = Utils.Object.getObjectIcon(path);
        currentPanel.filters = filters;

        if (Utils.Object.isAnalysis(path)) {
          const viewTypes = await Api.Analysis.getViewType(path);
          if (viewTypes.chart) {
            currentPanel.viewType = 'CHART';
          } else {
            currentPanel.viewType = 'TABLE';
          }
        }
      }
      await this.panelChanged(currentPanel);
      this.setState({
        navigationStack,
      });
    } catch (e) {
      console.error('Error on onOpenObject', { path, filters, forceFilters }, e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  async fetchCockpits() {
    return _.sortBy(await Api.Cockpit.findCockpits(this.props.context.project.name), (c) => !c.favorite);
  }

  favoriteChanged = async (cockpit) => {
    const cockpits = await this.fetchCockpits();
    this.setState({ cockpits });
  };

  async updateBreadcrumbUrl() {
    const uri = `${window.location.pathname}${window.location.search}`;
    await Api.Breadcrumb.updateCurrent(uri);
  }

  loadCockpit = async (cockpit) => {
    window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
    cockpit = await Api.Cockpit.findOne(cockpit.id, true);
    Utils.History.updateQuery({
      cockpitId: cockpit.id,
    });
    this.updateBreadcrumbUrl();
    await new Promise((res) => {
      this.setState({ cockpit, navigationStack: [] }, async () => {
        try {
          const panelId = _.parseInt(Utils.History.currentUrlSearchParams().get('panelId') || '-1');
          const currentPanel = cockpit.panels.find((p) => p.id === panelId);
          await this.panelChanged(currentPanel ?? cockpit.panels[0], cockpit);
        } finally {
          res();
        }
      });
    });
  };

  panelChanged = async (panel, cockpit = this.state.cockpit) => {
    window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
    Utils.History.updateQuery({
      panelId: panel ? panel.id : null,
    });
    this.updateBreadcrumbUrl();
    delete window.__BNG_COCKPIT_VIEW_CURRENT_PATH;
    if (panel) {
      let panelStructureNotLoaded = false;
      if (panel.path) {
        window.__BNG_COCKPIT_VIEW_CURRENT_PATH = panel.path;
        try {
          const response = await Api.Project.findLastUpdate(panel.path);
          panelStructureNotLoaded = !response.objectStructureLoadedOnce;
        } catch (e) {
          console.warn('Error findLastUpdate()', { panel }, e);
        }
      }
      await this.loadCockpitFilters(cockpit, panel);
      this.setState({
        currentPanel: panel,
        panelStructureNotLoaded,
      });
    }
    bimEventBus.emit('BngCockpitView:panelChanged', { panel });
  };

  onFilterChanged = async (globalFilters) => {
    this.setState({ globalFilters });
  };

  loadCockpitFilters = async (cockpit, panel) => {
    try {
      const filterView = await Api.Cockpit.statelessFilterView(
        cockpit.id,
        panel.id,
        FilterService.createFilterParam(
          [...this.state.globalFilters.active, ...this.state.globalFilters.inactive],
          true
        ).filter
      );
      this.updateGlobalFilters(filterView);
    } catch (e) {
      console.error('Error on loadCockpitFilters', { cockpit, panel }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  onChangeFilter = async (filter, selectedMembers) => {
    try {
      const newFilters = _.cloneDeep([...this.state.globalFilters.active, ...this.state.globalFilters.inactive]);

      const matchedFilter = newFilters.find((f) => f.id === filter.id);
      if (matchedFilter) {
        matchedFilter.selectedMembers = selectedMembers;
      }

      const filterView = await Api.Cockpit.statelessFilterView(
        this.state.cockpit.id,
        this.state.currentPanel.id,
        FilterService.createFilterParam(newFilters, true).filter
      );

      this.updateGlobalFilters(filterView);

      // Dashboard
      if (Utils.Object.isDashboard(this.state.currentPanel?.path || '')) {
        useDashboardPageCtx.getState().filterChangeHandler([matchedFilter], false, true);
      }
    } catch (e) {
      console.error('Error on onChangeFilter', { filter, selectedMembers }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  updateGlobalFilters = (filters = {}) => {
    if (filters === null) {
      return;
    }

    this.populateSelectedMembers(filters.enabled, filters.filledFilters);
    this.populateSelectedMembers(filters.disabled, filters.filledFilters);
    filters.enabled = FilterService.transform(filters.enabled);
    this.setState({
      globalFilters: {
        inactive: filters.disabled,
        active: filters.enabled,
        isFiltered: filters.filtered,
      },
    });
  };

  populateSelectedMembers = (filters, filledFilters) => {
    filters.map((filter) => {
      if (!filter.selectedMembers) {
        filter.selectedMembers = [];
      }
      for (let filled of filledFilters) {
        if (filled.id === filter.id) {
          filter.selectedMembers = filled.selectedMembers;
          break;
        }
      }
    });
  };

  buildBreadcrumbProps(currentPanel) {
    if (_.isEmpty(this.state.navigationStack)) {
      return null;
    }

    return {
      currentPage: {
        nameFor: currentPanel.name,
      },
      pages: this.state.navigationStack.map((p, idx) => ({
        nameFor: `${idx === 0 ? `Cockpit - ` : ''}${p.name}`,
        highlight: true,
        onClick: async () => {
          this.setState({ loading: true });
          try {
            await this.panelChanged(p);
            const navigationStack = this.state.navigationStack.slice(0, idx);
            this.setState({
              navigationStack,
            });
          } catch (e) {
            console.error('Error while opening breadcrumb item', { panel: p, idx }, e);
          } finally {
            this.setState({ loading: false });
          }
        },
      })),
    };
  }

  render() {
    const { cockpits, cockpit, currentPanel, panelStructureNotLoaded, $cockpitPanel } = this.state;

    const size = {
      w: this.$root ? this.$root.clientWidth : 0,
      h: this.$root ? this.$root.clientHeight : 0,
    };

    const renderEmpty = !cockpit || _.isEmpty(cockpit.panels) || !currentPanel;
    const isDashboard = currentPanel ? Utils.Object.isDashboard(currentPanel.path || '') : false;
    const breadcrumbProps = this.buildBreadcrumbProps(currentPanel);
    const panelFilters = currentPanel?.filters ?? this.state.globalFilters?.active;

    return (
      <div className="BngCockpitView" ref={(ref) => (this.$root = ref)}>
        {breadcrumbProps && <Breadcrumb className="CockpitBreadcrumb" logoIcon="table_chart" {...breadcrumbProps} />}

        {this.state.loading && <LoadingCenter background />}

        {!_.isNil(cockpits) && (
          <React.Fragment>
            <CockpitHeader>
              <BngCockpitNavigator
                cockpits={cockpits}
                currentCockpit={cockpit}
                favoriteChanged={this.favoriteChanged}
                onCockpitChange={this.loadCockpit}
              />

              {!renderEmpty && (
                <>
                  <BngCockpitTabs
                    panels={cockpit.panels}
                    enablePainelColors={cockpit.enablePainelColors}
                    currentPanelId={currentPanel.id}
                    onSelect={this.panelChanged}
                  />

                  <BngCockpitButtons
                    cockpit={cockpit}
                    currentPanel={currentPanel}
                    filters={this.state.globalFilters}
                    onPanelChange={this.panelChanged}
                    onFilterChanged={this.onChangeFilter}
                  />
                </>
              )}
            </CockpitHeader>
            <CockpitBody ref={(ref) => this.setState({ $cockpitPanel: ref })}>
              <BngEmpty message={this.props.context.msg.t('cockpit.without.panel.empty.title')} isEmpty={renderEmpty}>
                <>
                  {panelStructureNotLoaded ? (
                    <StructureNotLoadedPage
                      renderForCockpit={true}
                      onClick={
                        isDashboard
                          ? () => {
                              this.setState({
                                panelStructureNotLoaded: false,
                              });
                            }
                          : null
                      }
                    />
                  ) : (
                    currentPanel &&
                    $cockpitPanel && (
                      <React.Fragment key={`panel-${currentPanel.id}`}>
                        {currentPanel.type === TYPES.INDEX_PAGE_ITEM && (
                          <IndexPage
                            title={currentPanel.name}
                            icon={currentPanel.icon}
                            content={currentPanel.content}
                            panels={cockpit.panels}
                            columnCount={3}
                            onSelect={this.panelChanged}
                            location="cockpit"
                          />
                        )}

                        {(currentPanel.type === TYPES.HTML_ITEM || currentPanel.type === TYPES.COVER_PAGE_ITEM) && (
                          <HtmlPage panel={currentPanel} />
                        )}

                        {currentPanel.type === TYPES.PATH_AWARE_ITEM && (
                          <React.Fragment key={currentPanel.path}>
                            {Utils.Object.isKpi(currentPanel.path) && (
                              <Kpi
                                path={currentPanel.path}
                                width={size.w * 0.9}
                                height={size.h * 0.9}
                                filters={panelFilters}
                              />
                            )}
                            {Utils.Object.isDashboard(currentPanel.path) && (
                              <CockpitDashboardRender
                                DashComponent={DashGridEditor}
                                path={currentPanel.path}
                                initialFilters={panelFilters}
                                fromCockpit={cockpit.id}
                              />
                            )}
                            {Utils.Object.isNewMap(currentPanel.path) && (
                              <BiSource
                                mapPath={currentPanel.path}
                                mdxFilters={panelFilters}
                                fromCockpit={cockpit.id}
                              />
                            )}
                            {Utils.Object.isAnalysis(currentPanel.path) && (
                              <CockpitAnalysisRender
                                path={currentPanel.path}
                                filters={panelFilters}
                                viewType={currentPanel.viewType}
                                cockpitId={cockpit.id}
                                $cockpitPanel={$cockpitPanel}
                              />
                            )}
                            {Utils.Object.isBigTable(currentPanel.path) && (
                              <BigTable
                                id={Utils.Object.extractIdFromPath(currentPanel.path)}
                                createMode={false}
                                viewOnly={true}
                                filters={undefined}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )
                  )}
                </>
              </BngEmpty>
            </CockpitBody>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(BngCockpitView);
