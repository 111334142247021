import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContextEnhancer from 'components/ContextEnhancer';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';
import LoadingPlaceholder from 'components/ui/loading/LoadingPlaceholder';
import AGTable from 'components/bng/pages/bigTable/AGTable';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import { processQueryResult } from 'components/bng/pages/bigTable/functions';

const BigTableRenderer = ContextEnhancer(
  class InnerBigTableRenderer extends React.PureComponent {
    static propTypes = {
      dashboardStyle: PropTypes.object,
      highlight: PropTypes.bool,
      path: PropTypes.string,
      filters: PropTypes.any,
      width: PropTypes.number,
      height: PropTypes.number,
      align: PropTypes.string,
      data: PropTypes.object,
      exporting: PropTypes.bool,
      onTotalHeightChange: PropTypes.func,
      fullHeight: PropTypes.bool,
      prefetchedData: PropTypes.any,
      onDashboard: PropTypes.bool,
      dashboardPath: PropTypes.string,
      bigtableFilterModel: PropTypes.array,
      bigtableSortModel: PropTypes.array,
      isPresentation: PropTypes.bool,
      onResult: PropTypes.func,
      exportProps: PropTypes.object,
    };

    static defaultProps = {
      showErrorDialog: null,
      dashboardStyle: {},
      highlight: false,
      path: '',
      width: undefined,
      height: undefined,
      align: '',
      exporting: false,
      fullHeight: false,
      prefetchedData: null,
      onDashboard: false,
      dashboardPath: undefined,
      bigtableFilterModel: [],
      bigtableSortModel: [],
      isPresentation: false,
      onResult: _.noop,
      exportProps: {},
    };

    constructor(props) {
      super(props);
    }

    state = {
      loading: true,
      columns: [],
      rows: [],
      sortModel: null,
      gridConfig: null,
      themeConfig: null,
      titleConfig: null,
      totalizerConfig: null,
      bigTable: null,
      error: null,
      structureNotLoaded: false,
      ready: false,
      totalTableHeight: this.props.height,
      noData: false,
      selectedRows: [],
      queryResult: [],
    };

    async componentDidMount() {
      await this.load(true);
      this.fixPagesToExport();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if (
        !_.isEqual(prevProps.filters, this.props.filters) ||
        (!_.isEqual(prevProps.bigtableFilterModel, this.props.bigtableFilterModel) &&
          this.props.bigtableFilterModel?.filterMod.length > 0)
      ) {
        await this.load();
      }
      if (!_.isEqual(prevProps.bigtableSortModel, this.props.bigtableSortModel)) {
        this.updateSortModel();
      }
      this.fixPagesToExport();
    }

    async load(firstRender = false) {
      const { filters, path, prefetchedData, dashboardPath } = this.props;
      this.setState({ loading: true, error: null });
      try {
        const fetchedResult = prefetchedData
          ? Api.BigTable.processExecutionData(prefetchedData)
          : await Api.BigTable.executeByPath(path, filters, dashboardPath);
        const { bigTableDTO, result, noData, additionalProps, structureNotLoaded } = fetchedResult;
        let filterModel = Utils.BigTable.returnFilterModelForPath(path, this.props.bigtableFilterModel);
        const sortModel = Utils.BigTable.returnSortModelForPath(path, this.props.bigtableSortModel);

        if (this.props.exporting) {
          if (_.isArray(this.props.exportProps.bigtableSortModel)) {
            // multi sort not supported so 'clear' the persisted sorts before apply request sorts.
            // See: https://github.com/sol7/bi-machine/issues/7556
            sortModel.forEach((sm) => (sm.sort = null));

            for (const sm of this.props.exportProps.bigtableSortModel) {
              const match = sortModel.find((s) => s.colId === sm.colId);
              if (match) {
                match.sort = sm.sort || '';
              } else {
                sortModel.push(sm);
              }
            }

            if (this.props.exportProps.bigtableFilterModel) {
              filterModel = this.props.exportProps.bigtableFilterModel;
            }
          }
        }

        if (additionalProps.invalidPeriodicity) {
          UiMsg.warn(
            `${this.props.context.msg.t('attention')}!`,
            this.props.context.msg.t('invalid.date.filter.alert', [
              this.props.context.msg.t(additionalProps.invalidPeriodicity),
            ])
          );
        }

        const { columns, measureColumns, rows } = processQueryResult({
          columns: bigTableDTO.config.columns,
          queryResult: result,
          sortModel: firstRender ? bigTableDTO.config.sortModel : sortModel,
        });

        this.setState(
          {
            gridConfig: bigTableDTO.config.gridConfig,
            titleConfig: bigTableDTO.config.titleConfig,
            totalizerConfig: bigTableDTO.config.totalizerConfig,
            themeConfig: bigTableDTO.config.themeConfig,
            columns: bigTableDTO.config.columns,
            rows: rows ?? [],
            dataColumns: columns ?? [],
            measureColumns,
            sortModel: firstRender ? bigTableDTO.config.sortModel : sortModel,
            filterModel: firstRender ? bigTableDTO.config.filterModel : filterModel,
            noData,
            structureNotLoaded,
            bigTable: {
              id: bigTableDTO.id,
              name: bigTableDTO.name,
              description: bigTableDTO.description,
              parentFolder: bigTableDTO.parentFolder,
              mobile: bigTableDTO.mobile,
            },
            queryResult: result,
          },
          () => {
            this.props.onResult(fetchedResult);
          }
        );
      } catch (err) {
        this.setState({ error: err });
      } finally {
        this.setState({ loading: false });
      }
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, errorInfo) {
      console.error('BigTableRenderer catch an error', error, errorInfo);
    }

    showErrorDialog = () => {
      let message = this.state.error.response ? this.state.error.response.data.message : this.state.error.message;

      ObjectErrorPopup({ message: message });
    };

    fixPagesToExport = () => {
      if (this.props.onDashboard || !this.props.exporting) return;

      const agCenterColsClipper = document.querySelector('.ag-center-cols-clipper');
      const bigTableMainContainerExport = document.querySelector('.bigTable-main-container-export');
      const exportAsyncExportAsyncReady = bigTableMainContainerExport.childNodes[0];
      if (agCenterColsClipper && bigTableMainContainerExport && exportAsyncExportAsyncReady) {
        agCenterColsClipper.style.height = null;
        bigTableMainContainerExport.style.height = null;
        exportAsyncExportAsyncReady.style.height = null;
      }
    };

    processColumnsToExport(widthLimit) {
      if (
        this.state.gridConfig?.sizeToFit ||
        !this.props.exporting ||
        !Number.isFinite(widthLimit)
      ) {
        return this.state.columns;
      }

      let newColumns = [];

      let acc = widthLimit;
      for (let column of this.state.columns) {
        const colWidth = column.width ?? 0;
        if (acc - colWidth > 0) {
          newColumns.push(column);
          acc -= colWidth;
        } else {
          column = { ...column, width: acc };
          newColumns.push(column);
          break;
        }
      }

      return newColumns;
    }

    updateSortModel = () => {
      const sortModel = Utils.BigTable.returnSortModelForPath(this.props.path, this.props.bigtableSortModel);
      const { columns, measureColumns, rows } = processQueryResult({
        columns: this.state.columns,
        queryResult: this.state.queryResult,
        sortModel,
      });

      this.setState({
        rows: rows ?? [],
        dataColumns: columns ?? [],
        measureColumns,
        sortModel,
      })
    }

    render() {
      const { path, width, height, exporting, onDashboard } = this.props;

      if (this.state.structureNotLoaded) {
        let message = this.props.context.msg.t('dashboard.item.not.loaded.message');

        return (
          <DashboardItemInformation
            path={path}
            message={message}
            translationerrorMessage={true}
            width={onDashboard ? width : undefined}
            height={onDashboard ? height : undefined}
            showErrorDialog={null}
            snackbarType="not-loaded"
            snackbarIcon="cached"
          />
        );
      }

      if (this.state.error) {
        let message = this.props.context.msg.t('dashboard.item.error.message');
        let errorTrace = '';
        if (this.state.error.isAxiosError) {
          errorTrace = this.state.error.response?.data?.message;
        }

        if (this.props.exporting) {
          message = this.state.error.response ? this.state.error.response.data.message : this.state.error.message;
          message = JSON.stringify(message);
        }

        return (
          <DashboardItemInformation
            path={path}
            showErrorDialog={this.showErrorDialog}
            message={message}
            errorTrace={errorTrace}
            reload={() => this.load()}
            width={onDashboard ? width : undefined}
            height={onDashboard ? height : undefined}
          />
        );
      }

      if (this.state.noData) {
        return (
          <DashboardItemInformation
            path={path}
            width={onDashboard ? width : undefined}
            height={onDashboard ? height : undefined}
          />
        );
      }

      if (this.state.loading) {
        return (
          <div className="fill-w fill-h">
            <LoadingPlaceholder path={path} height={height} width={width} />
          </div>
        );
      }

      let style = { padding: 12 };
      style.height = height ? height - 24 : '100%';
      style.width = width ? width - 24 : '100%';
      if (this.props.fullHeight) {
        style.height = this.state.totalTableHeight + 50; //Size of bigtable was hiding 2 last results, probably because of the pagination footer, with this it shows all the results
      }

      // Obs Ciço:
      // Adicionado para corrigir problema de exportação: https://github.com/sol7/bi-machine/issues/8404#issuecomment-2361861066
      // Por algum motivo a exportação esta dando um 'zoom' mesmo com as colunas estando em um container com overflow hidden
      // Esse método basicamente vai filtrar as colunas que ficam fora da pagina de maneira manual
      const columns = this.processColumnsToExport(style.width);

      return (
        <div className={`ExportAsync ${this.state.ready ? 'ExportAsync-ready' : ''}`} style={style}>
          <AGTable
            id={this.state.bigTable ? this.state.bigTable.id : 'edit'}
            name={this.state.bigTable.name}
            description={this.state.bigTable.description}
            gridConfig={this.state.gridConfig}
            totalizerConfig={this.state.totalizerConfig}
            titleConfig={this.state.titleConfig}
            columns={columns}
            dataColumns={this.state.dataColumns}
            rows={this.state.rows}
            sortModel={this.state.sortModel}
            filterModel={this.state.filterModel}
            onTotalHeightChange={(th) => {
              if (this.props.onTotalHeightChange) {
                this.props.onTotalHeightChange(th);
                this.setState({ totalTableHeight: th });
              }
            }}
            sizeToFit={this.state.gridConfig.sizeToFit}
            onReady={({ gridApi }) => {
              if (this.props.exporting && !this.props.onDashboard) {
                gridApi.setDomLayout('print');
              }
              this.setState({ ready: true });
            }}
            onNoData={(event) => {
              this.setState({ noData: event });
            }}
            exporting={exporting}
            padding={exporting ? 24 : null}
            location={this.props.location}
            onDashboard={onDashboard}
            bigTablePath={this.props.path}
            isPresentation={this.props.isPresentation}
            filters={this.props.filters}
            selectedRows={this.state.selectedRows}
            onSelectedRowsChanged={({ selectedRows }) => {
              this.setState({ selectedRows });
            }}
          />
        </div>
      );
    }
  }
);

export default connect((state) => {
  const bigTableData = state?.bigTable?.data;
  return {
    bigtableFilterModel: bigTableData?.bigtableFilterModel ?? [],
    bigtableSortModel: bigTableData?.bigtableSortModel ?? [],
  };
})(BigTableRenderer);
