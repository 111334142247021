import styles from './IntegrationConnectionDialog.module.css';

import React, { useEffect, useState } from 'react';
import { Field, FieldArray, Formik, useFormikContext } from 'formik';

import BimEventBus from 'BimEventBus';
import { BngField } from 'components/bng/form/BngField';
import { BngForm } from 'components/bng/form/BngForm';
import { BngMaskedField } from 'components/bng/form/BngMaskedField';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngAddButton } from 'components/bng/ui/BngAddButton';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngInput from 'components/bng/form/BngInput';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngTable from 'components/bng/ui/BngTable';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import useBuildRouteAuthFields from 'components/hooks/bim-integration/useBuildRouteAuthFields';
import useBimIntegrationEndpoint from 'components/hooks/bim-integration/useBimIntegrationEndpoint';
import useValidateCredentials from 'components/hooks/bim-integration/useValidateCredentials';
import useEventBus from 'components/hooks/useEventBus';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import useTranslation from 'components/hooks/useTranslation';
import StructuresPageUtils, {
  EVENT as OAUTH_CALLBACK,
} from 'components/bng/pages/admin/structures/StructuresPageUtils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import { MaskType } from 'components/ui/in-memory/bim-integration/MaskType';
import { LabelHelper } from 'components/ui/in-memory/bim-integration/LabelHelper';

export const INVALIDATE_CREDENTIALS_INTEGRATION = 'IntegrationConnectionDialog:INVALIDATE_CREDENTIALS_INTEGRATION';

function MultipleItemsInput({ form, keyLabel, valueLabel, disabled }) {
  const context = useBimContext();

  useEffect(() => {
    if (checkForDuplicates(form.values.properties)) {
      UiMsg.warn(`${context.msg.t('inMemory_duplicatedFields_Message')}`);
    }
  }, [form.values.properties]);

  keyLabel = keyLabel ?? keyLabel;
  valueLabel = valueLabel ?? context.msg.t('bim.integration.base.url');

  const propertiesColumns = [
    {
      label: keyLabel,
      render: (row, index) => {
        return <Field name={`properties.${index}.key`} component={BngInput} disabled={disabled} required />;
      },
    },
    {
      label: valueLabel,
      render: (row, index) => {
        return <Field name={`properties.${index}.value`} component={BngInput} disabled={disabled} required />;
      },
    },
    {
      label: '',
      render: (row, index, rowProps) => {
        const disabled = rowProps.form.values?.properties?.length === 1;
        return (
          <BngIconButton
            className="remove-property-button"
            icon={'delete'}
            title={context.msg.t('delete')}
            disabled={disabled}
            onClick={disabled ? undefined : () => rowProps.remove(index)}
          />
        );
      },
    },
  ];

  const checkForDuplicates = (array) => {
    if (array === undefined || array === []) return false;
    const seen = {};
    for (const item of array) {
      if (seen[item.key] && seen[item.key] === item.value) {
        return true;
      }
      seen[item.key] = item.value;
    }
    return false;
  };

  const addProperty = (arrayHelpers) => {
    arrayHelpers.push({ key: '', value: '' });
    BimEventBus.emit(INVALIDATE_CREDENTIALS_INTEGRATION);
  };

  return (
    <FieldArray
      name="properties"
      render={(arrayHelpers) => (
        <div>
          <BngAddButton disabled={disabled} onClick={() => addProperty(arrayHelpers)}>
            <span>{context.msg.t('add')}</span>
          </BngAddButton>
          <div className={`${styles.containerTable}`}>
            <BngTable cols={propertiesColumns} rows={form.values.properties} rowProps={arrayHelpers} />
          </div>
        </div>
      )}
    />
  );
}

const cachedFindOauthConfigs = Utils.Cache.memoize(Api.BimIntegration.findOauthConfigs);

function IntegrationConnectionBody({ closeModal = _.noop, name = '', connectionId = 0 }) {
  const { values, setFieldValue, errors, setErrors } = useFormikContext();
  const formContainErrors = !_.isEmpty(errors);

  const context = useBimContext();
  const edit = !!connectionId;

  const [metaAccountOpts, setMetaAccountOpts] = useState([]);

  const [integrationInfo] = useBimIntegrationEndpoint(name);
  const [validateCredentialsFn] = useValidateCredentials({
    name,
    authType: integrationInfo.authType,
    initialCredentialsValid: false,
    fromScheduler: false,
  });

  const [routeQueryParams, setRouteQueryParams] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data: oauthConfigs = [] } = useFetchData(cachedFindOauthConfigs);
  const routeAuthFields = useBuildRouteAuthFields(name, integrationInfo.authType, context);

  useEffect(() => {
    if (_.isEmpty(integrationInfo)) {
      return;
    }

    const { endpoints } = integrationInfo;
    const extractEndpoints = [];
    endpoints.forEach((endpoint) => {
      if (endpoint.params) {
        endpoint.params.forEach((param) => {
          if (param.needInConnection === true && !extractEndpoints.some((p) => p.name === param.name)) {
            extractEndpoints.push(param);
          }
        });
      }
    });

    setRouteQueryParams(extractEndpoints);
  }, [integrationInfo]);

  useEffect(() => {
    if (_.isEmpty(integrationInfo)) {
      return;
    }

    if (integrationInfo?.authType) {
      setFieldValue('authName', integrationInfo.authType.name);
    }

    if (integrationInfo?.authType?.name === 'bim_open_data') {
      setFieldValue('project_id', `${context.applicationEnvironment}_${context.project.name}`);
    }
  }, [integrationInfo]);

  useEffect(() => {
    const errors = {};
    const requiredFieldMessage = context.msg.t('yup.mixed.required');
    let containsErrors = false;

    if (!values.name_connection) {
      errors.name_connection = requiredFieldMessage;
      containsErrors = true;
    }

    if (_.isArray(values?.properties)) {
      const val = values?.properties[0];
      if (val && _.isEmpty(val.key) && _.isEmpty(val.value)) {
        errors[val.key] = requiredFieldMessage;
        containsErrors = true;
      }
    } else {
      routeAuthFields.forEach((item) => {
        if (!values[item.name]) {
          errors[item.name] = requiredFieldMessage;
          containsErrors = true;
        }
      });
    }

    routeQueryParams.forEach((item) => {
      if (!values[item.name] && item.required) {
        errors[item.name] = requiredFieldMessage;
        containsErrors = true;
      }
    });

    setErrors(errors);
  });

  useAsyncEffect({
    onMount: async () => {
      await updateAccountsMeta();
    },
  });

  useEventBus(
    OAUTH_CALLBACK,
    async ({ refreshToken }) => {
      let validate = true;
      if (integrationInfo.name.startsWith('Meta') || integrationInfo.name.startsWith('Insta')) {
        validate = await updateAccountsMeta(refreshToken);
      }

      if (validate) {
        await validateCredentials({
          ...values,
          refresh_token: refreshToken,
        });
        setFieldValue('refresh_token', refreshToken);
      }
    },
    [integrationInfo]
  );

  const handleInputAuthFields = (routeAuthField) => {
    const commonParams = {
      key: routeAuthField.name,
      name: routeAuthField.name,
      component: BngField,
      label: routeAuthField.label,
      required: true,
      type: routeAuthField.type,
      maxLength: 2000,
    };

    if (routeAuthField?.multipleItems) {
      commonParams.inputComponent = MultipleItemsInput;
      Object.assign(commonParams, routeAuthField.multipleItems);
    }

    return commonParams;
  };

  const handleInputQueryParam = (routeQueryParam) => {
    const commonParams = {
      key: routeQueryParam.name,
      name: routeQueryParam.name,
      component: BngField,
      required: routeQueryParam.required,
      maxLength: 2000,
      type: 'text',
      label: routeQueryParam.info ? (
        <LabelHelper helpLabel={routeQueryParam.info} label={routeQueryParam.label} />
      ) : (
        routeQueryParam.label
      ),
    };

    if (routeQueryParam?.options) {
      commonParams.inputComponent = BngSelectSearch;
      commonParams.options = routeQueryParam.options;
      return commonParams;
    }

    commonParams.inputComponent = !routeQueryParam.mask || routeQueryParam.mask === 'text' ? undefined : BngMaskedField;
    commonParams.mask = MaskType(routeQueryParam.mask);

    if (routeQueryParam.mask === 'number') {
      commonParams.asNumber = true;
    }

    if (routeQueryParam.mask === ('date_br' || 'date_en')) {
      commonParams.asDate = true;
    }

    return commonParams;
  };

  const handleOauthAuthenticationConfigs = async (integrationName) => {
    const baseUrl = Api.buildBaseUrl(true);
    let authUrl, authParams;

    if (integrationName.startsWith('Google')) {
      authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      authParams = {
        access_type: 'offline',
        prompt: 'consent',
        client_id: oauthConfigs.googleConf.clientId,
        response_type: 'code',
        scope: integrationInfo.scope,
        redirect_uri: `${oauthConfigs.googleConf.redirectUri}/api/origins/google/google-auth`,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('Meta') || integrationName.startsWith('Insta')) {
      authUrl = 'https://www.facebook.com/v18.0/dialog/oauth';
      authParams = {
        client_id: oauthConfigs.facebookConf.clientId,
        redirect_uri: oauthConfigs.facebookConf.redirectUri,
        response_type: 'code',
        display: 'popup',
        force_confirmation: 'true',
        scope: integrationInfo.scope,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('Microsoft')) {
      authUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
      authParams = {
        client_id: oauthConfigs.microsoftConf.clientId,
        response_type: 'code',
        redirect_uri: oauthConfigs.microsoftConf.redirectUri,
        response_mode: 'query',
        scope: integrationInfo.scope,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('EagleEye')) {
      authUrl = 'https://auth.eagleeyenetworks.com/oauth2/authorize';
      authParams = {
        scope: 'vms.all',
        client_id: oauthConfigs.eagleEyeConf.clientId,
        response_type: 'code',
        redirect_uri: oauthConfigs.eagleEyeConf.redirectUri,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('Mercado')) {
      authUrl = 'https://auth.mercadolivre.com.br/authorization';
      authParams = {
        response_type: 'code',
        client_id: oauthConfigs.mercadoLivreConf.clientId,
        redirect_uri: oauthConfigs.mercadoLivreConf.redirectUri,
        state: baseUrl
      };
    } else {
      throw new Error(`Unsupported integration: ${integrationName}`);
    }

    StructuresPageUtils.authenticationRedirect({ uri: authUrl, params: authParams });
  };

  const renderAuthorizationButtons = (integrationName) => {
    const socialAuthProps = {};
    if (integrationName.startsWith('Google')) {
      socialAuthProps.img = 'google.svg';
      socialAuthProps.imgAlt = 'Google Logo';
      socialAuthProps.label = context.msg.t('google.login.with.google');
      socialAuthProps.className = 'ButtonGoogle';
    } else if (integrationName.startsWith('Meta') || integrationName.startsWith('Insta')) {
      socialAuthProps.img = 'facebook-letter.png';
      socialAuthProps.imgAlt = 'Facebook Logo';
      socialAuthProps.label = context.msg.t('login.with.facebook');
      socialAuthProps.className = 'ButtonGoogle';
    } else if (integrationName.startsWith('Microsoft')) {
      socialAuthProps.img = 'microsoft.svg';
      socialAuthProps.imgAlt = 'Microsoft Logo';
      socialAuthProps.label = context.msg.t('login.with.microsoft');
      socialAuthProps.className = 'ButtonMicrosoft';
    } else if (integrationName.startsWith('EagleEye')) {
      socialAuthProps.img = 'eagle-eye.png';
      socialAuthProps.imgAlt = 'Eagle Eye';
      socialAuthProps.label = context.msg.t('login.with.eagle.eye');
      socialAuthProps.className = 'ButtonGoogle';
    } else if (integrationName.startsWith('Mercado')) {
      socialAuthProps.img = 'mercado-livre.png';
      socialAuthProps.imgAlt = 'Mercado Livre';
      socialAuthProps.label = context.msg.t('login.with.mercado.livre');
      socialAuthProps.className = 'ButtonGoogle';
    }

    if (!_.isEmpty(socialAuthProps)) {
      return (
        <Button
          className={`${styles.ButtonBase} ${styles[socialAuthProps.className]}`}
          onClick={() => handleOauthAuthenticationConfigs(integrationName)}
          type={'button'}
          loading={loading}
          disabled={formContainErrors}
        >
          <img
            src={`${Api.baseUrl()}/resources/images/socials/${socialAuthProps.img}`}
            alt={socialAuthProps.altImg}
            className="mr-1"
          />
          <div>{socialAuthProps.label}</div>
        </Button>
      );
    }

    return (
      <Button
        className={`${styles.ButtonBase} ${styles.ButtonTestConnection}`}
        onClick={() => validateCredentials(values)}
        type={'button'}
        loading={loading}
        disabled={formContainErrors}
      >
        <Icon icon={'check_box'} className="mr-2" />
        <div>{context.msg.t('test.connection')}</div>
      </Button>
    );
  };

  const validateCredentials = async (values) => {
    setLoading(true);
    try {
      await validateCredentialsFn(values);
    } catch (e) {
      console.error('Error on validateCredentials()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  async function updateAccountsMeta(token) {
    const refreshToken = token || values.refresh_token;
    let options = [];

    if (refreshToken && _.isEmpty(metaAccountOpts) && (name.startsWith('meta') || name.startsWith('insta'))) {
      try {
        const metaAccounts = await Api.BimIntegration.findMetaAccounts(refreshToken, name);

        if (_.isEmpty(metaAccounts.data)) {
          UiMsg.warn(context.msg.t('role.title.hint'), context.msg.t('forbidden.meta'));
          return false;
        }

        options = metaAccounts.data.map((acc) => ({
          icon: 'cloud',
          label: name.startsWith('meta')
              ? `${acc.name} (${acc.id})`
              : `${acc?.name} (${acc?.instagram_business_account.id})`,
          value: acc,
        }));

        options.push({
          icon: 'cloud',
          label: context.msg.t('all.accounts'),
          value: { id: 'allAccounts', accounts: [] },
        });

        setMetaAccountOpts(options);
        return true;
      } catch (e) {
        console.error('Error on updateAccountsMeta()', e);
        if (e.response && e.response.status === 403) {
          UiMsg.warn(context.msg.t('role.title.hint'), context.msg.t('forbidden.meta'));
          return false;
        } else {
          UiMsg.ajaxError(null, e);
          return true;
        }
      }
    }
  }

  return (
    <BngForm>
      <Dialog.Body>
        {integrationInfo.authType && (
          <>
            <Field
              name="name_connection"
              component={BngField}
              label={context.msg.t('name')}
              required={true}
              type="text"
            />

            {integrationInfo.authType.name === 'bim_open_data' && (
              <div style={{ display: 'none' }}>
                <Field
                  key="project_id"
                  name="project_id"
                  component={BngField}
                  label="project_id"
                  required={true}
                  type="text"
                />
              </div>
            )}

            {integrationInfo.authType.name === 'oauth2' && (
              <div>
                <div style={{ display: 'none' }}>
                  <Field
                    key="refresh_token"
                    name="refresh_token"
                    component={BngField}
                    label="refresh_token"
                    required={true}
                    type="text"
                    className="google-sheets-refresh-token"
                    disabled={true}
                  />
                </div>
                {(integrationInfo.name.startsWith('Meta') || integrationInfo.name.startsWith('Insta')) && (
                    <div>
                      <Field
                          name="selected_account"
                          key="selected_account"
                          component={BngField}
                          inputComponent={BngSelectSearch}
                          label={context.msg.t('select_an_account')}
                          emptyOption={true}
                          required={true}
                          disabled={metaAccountOpts.length < 1}
                          options={metaAccountOpts}
                          multiple={true}
                      />
                    </div>
                  )}
              </div>
            )}

            {!_.isEmpty(routeAuthFields) &&
              routeAuthFields.map((routeAuthField) => <Field {...handleInputAuthFields(routeAuthField)} />)}
            {!_.isEmpty(routeQueryParams) &&
              routeQueryParams.map((routeQueryParam) => <Field {...handleInputQueryParam(routeQueryParam)} />)}

            <div className={styles.ButtonsContainer}>{renderAuthorizationButtons(integrationInfo.name)}</div>
          </>
        )}
      </Dialog.Body>

      <Dialog.Footer>
        <DefaultDialogActions closeModal={closeModal} okLabel={edit ? 'save' : 'create'} disabled={formContainErrors} />
      </Dialog.Footer>
    </BngForm>
  );
}

export default function IntegrationConnectionDialog({
  name,
  initialValues,
  connectionId = '',
  fetchConnections = _.noop,
  closeModal = _.noop,
  onChange,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const edit = !!connectionId;

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const data = {
        id: connectionId,
        name: values.name_connection,
        props: Object.fromEntries(Object.entries(values).filter(([item]) => item !== 'name_connection')),
        projectId: context.project.id,
      };

      if (onChange) {
        onChange(_.cloneDeep(data));
      } else {
        await (edit ? Api.Connection.updateConnection(data, connectionId) : Api.Connection.saveNewConnection(data));
      }
      UiMsg.ok(t('fem.save_success', t('connection')));

      fetchConnections();
      closeModal();
    } catch (error) {
      if (error.response?.status === 409) {
        UiMsg.warn(t('name_alredy_taken'));
      } else if (error.response?.status === 400) {
        UiMsg.error(t('object.not.avaliable.on.mobile.title'), error);
      } else {
        console.error(error);
        UiMsg.error('', error);
      }
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog
      title={t(edit ? 'editing.connection' : 'new.connection')}
      className={`${styles.FormDialog}`}
      onClose={closeModal}
      loading={loading}
    >
      <Formik onSubmit={handleSubmit} initialValues={initialValues ? initialValues : { name }}>
        <IntegrationConnectionBody connectionId={connectionId} name={name} closeModal={closeModal} />
      </Formik>
    </Dialog>
  );
}
