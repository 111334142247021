import 'script-loader!legacy/vendor/jquery-ui/1.12.1/jquery-ui.min.js';

import './Dialog.css';
import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'imports/ReactBootstrapImport';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';

export const DIALOG_MODE = {
  MAXIMIZED: 'maximized',
  MINIMIZED: 'minimized',
};

export default class Dialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    titleComponent: PropTypes.any,
    hideHeader: PropTypes.bool,
    className: PropTypes.string,
    open: PropTypes.bool,
    showMaximize: PropTypes.bool,
    showMinimize: PropTypes.bool,
    onModeChanged: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    backdrop: PropTypes.any,
    backdropClassName: PropTypes.string,
    loading: PropTypes.bool,
    contentFullWidth: PropTypes.bool,
    newDialogLayout: PropTypes.bool,
    verticalCenter: PropTypes.bool,
    hideClose: PropTypes.bool,
    draggable: PropTypes.bool,
    icon: PropTypes.string,
    afterBody: PropTypes.any,
  };

  static defaultProps = {
    title: 'Title',
    hideHeader: false,
    className: '',
    open: true,
    onOpen: () => null,
    onClose: () => null,
    backdrop: 'static',
    backdropClassName: '',
    loading: false,
    contentFullWidth: false,
    newDialogLayout: false,
    verticalCenter: false,
    hideClose: false,
    draggable: true,
    icon: null,
    afterBody: null,
    onModeChanged: _.noop,
    showMaximize: false,
    showMinimize: false,
  };

  dialogId = Utils.randomId();

  state = {
    mode: '',
  };

  setupDraggable = (r) => {
    if (!this.props.draggable) return;
    let mobile = application.page.isMobile();
    if (r && !this.draggable && !mobile) {
      let $this = jQuery(r);
      let $dialog = $this.parents('.bs-dialog');
      let margin = 0;
      try {
        let leftMarginPx = $dialog.css('margin-left');
        if (leftMarginPx) {
          leftMarginPx = leftMarginPx.replace('px', '');
          margin = Math.abs(Number(leftMarginPx));
        }
      } catch (e) {}
      $dialog.draggable({
        handle: $this,
        containment: [margin, 0, 9999, 9999],
        start: () => {
          j(`.${this.dialogId}`).addClass('Dragged');
        },
      });
      this.draggable = true;
    }
  };

  toggleDialogMode = (e, mode = '') => {
    const newMode = this.state.mode === mode ? '' : mode;
    this.setState({
      mode: newMode,
    });
    this.props.onModeChanged(newMode);
  };

  closeButtonAction = (e) => {
    e.preventDefault();
    this.props.onClose(e);
  };

  render() {
    return (
      <Modal
        show={this.props.open}
        onHide={this.props.onClose}
        animation={this.props.animation || false}
        className={`
                   bs-dialog ${this.props.className} 
                   ${this.dialogId}
                   ${this.props.newDialogLayout ? 'DialogNewLayout' : ''}  
                   ${this.props.verticalCenter ? 'VerticalCenter' : ''}
                   ${this.state.mode}
                   `}
        backdrop={application.page.isMobile() ? true : this.props.backdrop}
        enforceFocus={false}
        backdropClassName={`${this.props.backdropClassName} ${this.state.mode === 'maximized' ? 'maximized' : ''}`}
      >
        <Modal.Body>
          <UiBlocker block={this.props.loading}>
            <div className="widget-box">
              {!this.props.hideHeader && (
                <>
                  <div
                    className={`widget-header ${this.props.draggable ? 'draggable-cursor' : ''} `}
                    ref={this.setupDraggable}
                  >
                    {this.props.icon && <Icon className="DialogIcon" icon={this.props.icon} />}
                    {!this.props.titleComponent && <h5>{this.props.title}</h5>}
                    {this.props.titleComponent && this.props.titleComponent()}
                  </div>
                  <div className="widget-toolbar">
                    {this.props.showMinimize && (
                      <a href="#" onClick={(e) => this.toggleDialogMode(e, DIALOG_MODE.MINIMIZED)}>
                        <div className="btnmodal btnmodalminimize">
                          <Icon icon={this.state.mode === DIALOG_MODE.MINIMIZED ? 'maximize' : 'minimize'} />
                        </div>
                      </a>
                    )}
                    {this.props.showMaximize && (
                      <a href="#" onClick={(e) => this.toggleDialogMode(e, DIALOG_MODE.MAXIMIZED)}>
                        <div className="btnmodal btnnmodalbtexpand">
                          <Icon icon={this.state.mode === DIALOG_MODE.MAXIMIZED ? 'fullscreen_exit' : 'fullscreen'} />
                        </div>
                      </a>
                    )}
                    {!this.props.hideClose && (
                      <a href="#" onClick={this.closeButtonAction}>
                        <div className="btnmodal btnnmodalbtclose">
                          <Icon icon="close" />
                        </div>
                      </a>
                    )}
                  </div>
                </>
              )}
              <div className={`widget-body  ${this.props.contentFullWidth ? 'content-full-width' : ''}`}>
                <div className="widget-main">
                  <div className="dialog-body">{this.props.children}</div>
                  {this.props.afterBody}
                </div>
              </div>
            </div>
          </UiBlocker>
        </Modal.Body>
      </Modal>
    );
  }
}

Dialog.Body = ({ className = '', children }) => <div className={`DialogBody ${className}`}>{children}</div>;

Dialog.Footer = ({ className = '', children }) => <div className={`DialogFooter ${className}`}>{children}</div>;
