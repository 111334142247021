import './BimIntegrationOrigin.css';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik, useFormikContext } from 'formik';

import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { BngField } from 'components/bng/form/BngField';
import { bngYupLang } from 'components/bng/form/yup/BngYup';
import { BngMaskedField } from 'components/bng/form/BngMaskedField';
import { BngForm } from 'components/bng/form/BngForm';
import { BngInput } from 'components/bng/form/BngInput';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import ContextEnhancer from 'components/ContextEnhancer';
import Button from 'components/ui/Button';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import IntegrationConnectionDialog from 'components/ui/in-memory/IntegrationConnectionDialog';
import useBimIntegrationEndpoint from 'components/hooks/bim-integration/useBimIntegrationEndpoint';
import useRoutePathTranslator from 'components/hooks/bim-integration/useRoutePathTranslator';
import useValidateCredentials from 'components/hooks/bim-integration/useValidateCredentials';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import { MaskType } from 'components/ui/in-memory/bim-integration/MaskType';
import { LabelHelper } from 'components/ui/in-memory/bim-integration/LabelHelper';
import { DONT_NEED_CONNECTION } from 'components/ui/in-memory/DataOriginsDialog';

const YupLang = bngYupLang((yup) => yup);

const validateCredentialsDates = (routeQueryParams, params) => {
  const typeDateInForm = [];
  for (const itemDate of routeQueryParams) {
    if (itemDate.mask === 'date_en' || itemDate.mask === 'date_br') {
      typeDateInForm.push({ name: itemDate.name, mask: itemDate.mask });
    }
  }

  let match = true;
  for (const item of typeDateInForm) {
    if (!!params[item.name]) {
      if (item.mask === 'date_en') {
        const regexDateEn = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/;
        return regexDateEn.test(params[item.name]);
      } else if (item.mask === 'date_br') {
        const regexDateBr = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
        return regexDateBr.test(params[item.name]);
      }
    }
  }
  return match;
};

const BimIntegrationOrigins = ({
  name,
  context,
  creating = false,
  isEditing = false,
  formikProps = null,
  fromScheduler = false,
  onFormParamsInfo = _.noop,
}) => {
  const { values, isSubmitting, setValues } = useFormikContext();

  const [optionsEndpoints, setOptionsEndpoint] = useState([]);
  const [routeQueryParams, setRouteQueryParams] = useState([]);
  const [prevRouteQueryParams, setPrevRouteQueryParams] = useState(null);

  const [integrationInfo] = useBimIntegrationEndpoint(name);
  const [validateCredentialsFn, isCredentialsValid] = useValidateCredentials({
    name,
    authType: integrationInfo.authType,
    initialCredentialsValid: false,
    fromScheduler,
  });

  const [routePathParams] = useRoutePathTranslator(values.endpoint);
  const [prevRoutePathParams, setPrevRoutePathParams] = useState(null);

  const [loadingTestGa4, setLoadingTestGa4] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [groupedOptions, setGroupedOptions] = useState(false);

  const { data: connections = [], reload: reloadConnections } = useFetchData(async () => {
    try {
      const data = await Api.Connection.findAll({ projectId: context.project.id, type: name });

      return data.map((connection) => {
        return {
          icon: 'cloud_done',
          value: connection,
          label: connection.name,
        };
      });
    } catch (e) {
      console.error('Error fetching connections', e);
      UiMsg.error(null, e);
    }
  }, []);

  useEffect(() => {
    if (prevRoutePathParams === null && routePathParams.length !== 0) {
      setPrevRoutePathParams(routePathParams);
    }

    if (routePathParams !== prevRoutePathParams && prevRoutePathParams !== null) {
      const tempValues = _.clone(values);
      prevRoutePathParams.forEach((item) => {
        delete tempValues[item.name];
      });
      setValues(tempValues);
      setPrevRoutePathParams(routePathParams);
    }
  }, [routePathParams]);

  useEffect(() => {
    if (prevRouteQueryParams === null && routeQueryParams.length !== 0) {
      setPrevRouteQueryParams(routeQueryParams);
    }

    if (routeQueryParams !== prevRouteQueryParams && prevRouteQueryParams !== null) {
      const tempValues = _.clone(values);
      prevRouteQueryParams.forEach((item) => {
        if (_.findIndex(routeQueryParams, item) === -1) delete tempValues[item.name];
      });
      setValues(tempValues);
      setPrevRouteQueryParams(routeQueryParams);
    }
  }, [routeQueryParams]);

  useEffect(() => {
    const $wizardNextButton =
      document.querySelector('.JsfWizardNextButton') || document.querySelector('.JsfWizardNextBtn');

    const blockButtonNext = () => {
      $wizardNextButton.disabled = 'disabled';
      $wizardNextButton.classList.remove('btn-next');
      $wizardNextButton.classList.add('btn-next-dis');
      $wizardNextButton.classList.remove('iceCmdBtn');
      $wizardNextButton.classList.add('iceCmdBtn-dis');
    };

    blockButtonNext();
    if (!$wizardNextButton) {
      return;
    }

    let emptyOption = false;

    const paramsEndpoint = integrationInfo?.endpoints?.find((item) => item.endpoint === values.endpoint);
    if (!_.isEmpty(routeQueryParams)) {
      emptyOption = !validateCredentialsDates(routeQueryParams, values);
    }

    for (const key in values) {
      const value = values[key].toString();
      const paramInfo = paramsEndpoint?.params.find((param) => param.name === key);
      if (_.isEmpty(value) && (!paramInfo || paramInfo?.required === true)) {
        emptyOption = true;
        break;
      }
    }

    if (emptyOption) {
      blockButtonNext();
    } else {
      $wizardNextButton.removeAttribute('disabled');
      $wizardNextButton.classList.remove('btn-next-dis');
      $wizardNextButton.classList.add('btn-next');
      $wizardNextButton.classList.remove('iceCmdBtn-dis');
      $wizardNextButton.classList.add('iceCmdBtn');
    }
  }, [values, integrationInfo]);

  useEffect(() => {
    if (_.isEmpty(integrationInfo)) {
      return;
    }

    // Update JSF rendered labels
    document.querySelectorAll('.ReplaceSourceLabel').forEach((el) => (el.innerHTML = integrationInfo.name));

    const opts = integrationInfo.endpoints
      .filter((i) => !i.logicEndpoint)
      .map((integration) => {
        return {
          icon: 'cloud',
          value: integration.endpoint,
          label: integration.name,
        };
      });
    const logicOpts = integrationInfo.endpoints
      .filter((i) => i.logicEndpoint)
      .map((integration) => {
        return {
          icon: 'cloud',
          value: integration.endpoint,
          label: integration.name,
        };
      });
    if (logicOpts.length > 0) {
      setGroupedOptions(true);
      const labelLogicOpts = integrationInfo.name.startsWith('Ponto')
        ? context.msg.t('reports_integrations')
        : context.msg.t('logic_integrations');

      setOptionsEndpoint([
        {
          icon: 'folder',
          label: context.msg.t('integrations'),
          children: opts,
        },
        {
          icon: 'device_hub',
          label: labelLogicOpts,
          children: logicOpts,
        },
      ]);
    } else {
      setGroupedOptions(false);
      setOptionsEndpoint(opts);
      if (opts.length === 1) {
        formikProps.setFieldValue('endpoint', opts[0].value);
      }
    }
    if (integrationInfo?.authType?.name === 'bim_open_data') {
      formikProps.setFieldValue('project_id', `${context.applicationEnvironment}_${context.project.name}`);
    }
  }, [integrationInfo]);

  useEffect(filterQueryParamsFromEndpoint, [values.endpoint, integrationInfo]);

  function filterQueryParamsFromEndpoint() {
    if (!(!_.isEmpty(values.endpoint) && !_.isEmpty(integrationInfo))) {
      return;
    }

    const { endpoints } = integrationInfo;
    const selectedIntegration = endpoints.find((integration) => integration.endpoint === values.endpoint);
    const qp = selectedIntegration.params.filter((param) => !param.needInConnection) || [];
    setRouteQueryParams(qp);
    onFormParamsInfo({ routeQueryParams: qp });
  }

  async function updateWsParams() {
    if (_.isEmpty(values)) {
      return;
    }

    try {
      const { authType } = integrationInfo;

      const { connection, ignoreLoadErrors, ...params } = values;
      const props = Object.assign({}, params, connection?.props, { fields: authType.fields });

      await Api.BimIntegration.addWsParams({
        connectionId: connection?.id,
        ignoreLoadErrors,
        props,
        fromScheduler: fromScheduler,
      });
      await Api.BimIntegration.updateForm();
    } catch (e) {
      console.error('Error on updateWsParams()', e);
    }
  }

  async function validateCredentials() {
    if (_.isEmpty(values)) {
      return;
    }

    try {
      const { authType } = integrationInfo;
      const { connection, ignoreLoadErrors, ...props } = values;
      const params = Object.assign({}, props, connection?.props, { fields: authType.fields });

      await validateCredentialsFn(params);
      await updateWsParams();
    } catch (e) {
      console.error('Error on validateCredentials()', e);
    }
  }

  const handleInputQueryParam = (routeQueryParam) => {
    const commonParams = {
      key: routeQueryParam.name,
      name: routeQueryParam.name,
      component: BngField,
      disabled: disableFields,
      required: routeQueryParam.required,
      maxLength: 2000,
      multiple: routeQueryParam?.multiple,
      type: 'text',
      label: routeQueryParam.info ? (
        <LabelHelper helpLabel={routeQueryParam.info} label={routeQueryParam.label} />
      ) : (
        routeQueryParam.label
      ),
    };

    if (routeQueryParam?.options) {
      commonParams.inputComponent = BngSelectSearch;
      commonParams.options = routeQueryParam.options;
      return commonParams;
    }

    commonParams.inputComponent = !routeQueryParam.mask || routeQueryParam.mask === 'text' ? undefined : BngMaskedField;
    commonParams.mask = MaskType(routeQueryParam.mask);

    switch (routeQueryParam.mask) {
      case 'number': {
        commonParams.asNumber = true;
        break;
      }
      case 'date_en':
      case 'date_br': {
        commonParams.asDate = routeQueryParam.mask.split('_')[1];
        break;
      }
    }
    return commonParams;
  };

  const openConnectionDialog = () => {
    ReduxStore.dispatch(
      MODALS.open(IntegrationConnectionDialog, {
        name,
        fetchConnections: () => reloadConnections(),
      })
    );
  };

  const disableFields = creating && !isEditing;
  const needConnection = !DONT_NEED_CONNECTION.includes(name);

  async function testGAParam() {
    if (_.isEmpty(_.omit(values, ['connection', 'endpoint', 'ignoreLoadErrors', 'name']))) {
      UiMsg.error(context.msg.t('bim.integration.empty'));
      return;
    }

    try {
      setLoadingTestGa4(true);
      await Api.BimIntegration.testGAParam({
        refresh_token: values.connection.props.refresh_token,
        dimensions: values.dimensions,
        metrics: values.metrics,
      });

      UiMsg.ok(context.msg.t('bim.integration.param.valid'));
    } catch (e) {
      console.error('Error on testGAParam()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoadingTestGa4(false);
    }
  }

  return (
    <div className="BimIntegrationDataOrigin">
      <div className="flex-center-items">
        <h6>{context.msg.t('bim.integration.origins.title')}</h6>
        <div className="ml-auto flex-center-items">
          <Field
            name="ignoreLoadErrors"
            component={BngCheckbox}
            className="IgnoreLoadErrorsCheckbox"
            label={context.msg.t('newInMemoryIgnoreEmptyColumns')}
            title={context.msg.t('newInMemoryIgnoreParseErrorsHint')}
          />
        </div>
      </div>
      <hr />

      {needConnection && (
        <div className="Connections">
          <Field
            name="connection"
            component={BngField}
            inputComponent={BngSelectSearch}
            label={context.msg.t('select.one.connection')}
            emptyOption={true}
            required={true}
            disabled={disableFields || !connections.length > 0}
            options={connections}
            rootClassName="ConnectionsField"
          />
          <Button className="ConnectionsButton" icon="add" onClick={openConnectionDialog} disabled={disableFields} />
        </div>
      )}

      {optionsEndpoints.length > 1 && (
        <Field
          name="endpoint"
          component={BngField}
          inputComponent={BngSelectSearch}
          groupedOpts={groupedOptions}
          label={context.msg.t('bim.integration.select.integration')}
          emptyOption={true}
          required={true}
          disabled={disableFields}
          options={optionsEndpoints}
        />
      )}
      {integrationInfo.authType && (
        <>
          {integrationInfo.authType.name === 'bim_open_data' && (
            <div style={{ display: 'none' }}>
              <Field
                name="project_id"
                component={BngField}
                label="project_id"
                required={true}
                disabled={disableFields}
                type="text"
              />
            </div>
          )}

          {integrationInfo.authType.name === 'oauth2' && (
            <>
              <div style={{ display: 'none' }}>
                <Field
                  key="refresh_token"
                  name="refresh_token"
                  component={BngField}
                  label="refresh_token"
                  required={true}
                  type="text"
                  className="google-sheets-refresh-token"
                  disabled={true}
                />
              </div>
              {values.endpoint === '/googleAnalytics/blank-endpoint' && (
                <div>
                  <Button
                    icon="science"
                    type="button"
                    onClick={() => testGAParam()}
                    className="BtnTestGA4"
                    loading={loadingTestGa4}
                  >
                    {context.msg.t('bim.integration.test.param')}
                  </Button>
                </div>
              )}
              {integrationInfo.name.startsWith('Microsoft') && (
                <div>
                  <Field
                    name="selected_worksheet"
                    component={BngField}
                    inputComponent={BngInput}
                    label={context.msg.t('select.worksheet')}
                    required={true}
                  />
                </div>
              )}
            </>
          )}

          {!_.isEmpty(routePathParams) &&
            routePathParams.map((routeParam) => {
              return (
                <Field
                  key={routeParam.name}
                  name={routeParam.name}
                  type={'text'}
                  required={true}
                  component={BngField}
                  disabled={disableFields}
                  label={routeParam.label}
                />
              );
            })}

          {!_.isEmpty(routeQueryParams) &&
            routeQueryParams.map((routeQueryParam) => <Field {...handleInputQueryParam(routeQueryParam)} />)}

          <div className="AbsoluteButtons">
            <Button
              icon="icon-ok"
              type="button"
              onClick={() => validateCredentials()}
              loading={isSubmitting}
              className="BtnValidate"
            >
              {context.msg.t('bim.integration.validate.credentials')}
            </Button>

            <Button
              className="btn btn-success BtnValidCredentials"
              disabled={false}
              style={{ display: isCredentialsValid ? 'initial' : 'none' }}
            >
              <span className="fa fa-check" />
            </Button>
            <Button
              icon="icon-eye-open"
              onClick={async () => {
                setLoadingPreview(true);
                try {
                  await updateWsParams();
                  const source = fromScheduler ? 'inMemorySchedulingFormMB.scheduling.source' : 'inMemoryBean.source';
                  await Api.executeExp(`#{sourcePreviewMB.openPreview(inMemoryBean.inMemory, ${source})}`);
                } finally {
                  setLoadingPreview(false);
                }
              }}
              loading={loadingPreview}
              className="BtnPreview btn-inverse"
            >
              {context.msg.t('data.preview')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const FormikWrapper = ({ name, state, context, ignoreLoadErrors = true, ...rest }) => {
  const [initialValues] = useState({
    ...(JSON.parse(state) || {}),
    ignoreLoadErrors: _.isBoolean(ignoreLoadErrors) ? ignoreLoadErrors : ignoreLoadErrors === 'true',
  });
  const [formParamsInfo, setFormParamsInfo] = useState({ routeQueryParams: [] });

  const validate = useCallback(
    (values) => {
      const errors = {};
      const requiredFieldMessage = YupLang.mixed.required;

      const keys = Object.keys(values);

      for (const field of keys) {
        const value = values[field];
        if (!value && !_.isBoolean(value)) {
          const paramInfo = formParamsInfo.routeQueryParams.find((qp) => qp.name === field);
          if (!paramInfo || paramInfo.required === true) {
            errors[field] = requiredFieldMessage;
          }
        }
      }

      if (_.isEmpty(values.endpoint) && _.isEmpty(values.project_id)) {
        errors.endpoint = requiredFieldMessage;
      }

      return errors;
    },
    [formParamsInfo]
  );

  const formChangeListener = useCallback(
    _.debounce((next) => {
      const { connection, ignoreLoadErrors, ...params } = next.values;
      const connectionValue = connection ? connection : initialValues.connection;
      const props = Object.assign({}, params, connection?.props, { connection: connectionValue });

      Api.BimIntegration.addWsParams({
        connectionId: connection?.id ?? null,
        ignoreLoadErrors,
        props,
        fromScheduler: rest.fromScheduler ?? false,
      });
    }, 300),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={_.noop}
      validate={validate}
      validateOnChange={true}
      isInitialValid={true}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <BngForm>
          <FormikListener onChange={formChangeListener} />
          <BimIntegrationOrigins
            name={name}
            context={context}
            formikProps={formikProps}
            onFormParamsInfo={(p) => {
              setFormParamsInfo({
                ...formParamsInfo,
                ...p,
              });
            }}
            {...rest}
          />
        </BngForm>
      )}
    </Formik>
  );
};

BimIntegrationOrigins.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ContextEnhancer(FormikWrapper);
