'use strict';

import { Axios } from 'commonUtils';
import BimEventBus from 'BimEventBus';

const $ = jQuery;

const THEME_CACHE = {};

class ProjectApi {
  static FOLDER_CREATED_EVENT = 'ProjectApi:createFolder';

  /**
   *
   * @param projectId
   * @param skipPermissionCheck
   * @returns {Promise.<T>}
   */
  static async findSources({ projectId, skipPermissionCheck }) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/sources/xmla`, {
      params: {
        skipPermissionCheck: skipPermissionCheck || undefined,
      },
    });

    data.forEach((group) => {
      group.icon = 'folder';
      group.selectItems.forEach((source) => {
        source.icon = source.attributes?.icon || 'insert_chart';
      });
      group.children = group.selectItems;
    });

    return data;
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @param cube
   * @returns {Promise.<T>}
   */
  static sourceFields({ projectId, sourceName }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/sources/xmla/${sourceName}/fields`));
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @param cube
   * @returns {Promise.<T>}
   */
  static sourceTimeFields({ projectId, sourceName }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/sources/xmla/${sourceName}/fields/time`));
  }

  /**
   *
   * @param projectName
   * @param cube
   * @returns {Promise.<T>}
   */
  static cubeCaption({ projectName, cube }) {
    return Promise.resolve($.getJSON(`/spr/projects/caption-information/?${$.param({ projectName, cube })}`));
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @returns {Promise.<T>}
   */
  static async queryStructure({ projectId, sourceName }) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/sources/xmla/${sourceName}/queryStructure`);
    return data;
  }

  static async findDimensionMembers({ projectId, sourceName, dimension }) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/sources/xmla/${sourceName}/members`, {
      params: {
        dimension,
      },
    });
    return data;
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @returns {Promise.<T>}
   */
  static lastDataUpdateByProject({ projectId, sourceName }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/last-update/?${$.param({ sourceName })}`));
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @returns {Promise.<T>}
   */
  static sourceCubes({ projectId, sourceName }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/sources/xmla/${sourceName}/cubes`));
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @returns {Promise.<T>}
   */
  static geoFieldCheck({ projectId, sourceName }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/sources/xmla/${sourceName}/need-migration`));
  }

  /**
   *
   * @param projectId
   * @param sourceName
   * @returns {Promise.<T>}
   */
  static regenerateXml({ projectId, sourceName }) {
    return Promise.resolve($.post(`/spr/projects/${projectId}/sources/xmla/${sourceName}/regenerate-xml`));
  }

  /**
   *
   * @param projectId
   * @param folder
   * @param name
   * @returns {Promise.<T>}
   */
  static nameAlreadyInUse({ projectId, folder, name }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/validate/name-in-use?${$.param({ folder, name })}`));
  }

  /**
   *
   * @param projectId
   * @param path
   * @param folder
   * @param name
   * @param description
   * @returns {Promise.<T>}
   */
  static async renameObject({ projectId, path, folder, name, description }) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/rename`, {
      path,
      folder,
      name,
      description,
    });
    return data;
  }

  static async toggleEnabled(projectId) {
    await Axios.post(`/spr/projects/${projectId}/toggleEnabled`);
  }

  static async folderChildObjects(projectId, folderPath) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/folderChildObjects`, {
      params: {
        folderPath,
      },
    });
    return data;
  }

  static changeProjectType(projectType) {
    return Promise.resolve(j.postJSON(`/spr/projects/changetype`, projectType));
  }

  static findTransferQuota(projectId) {
    return Promise.resolve(j.getJSON(`/spr/projects/${projectId}/transfer-quota`));
  }

  static async isProjectNameInUse(projectName) {
    return await j.getJSON(`/spr/projects/name-in-use?${j.param({ projectName })}`);
  }

  static async getByName(projectName) {
    return await j.getJSON(`/spr/projects/get-by-name?${j.param({ projectName })}`);
  }

  static async findProjectInfoForAddonPage(projectId) {
    return await j.getJSON(`/spr/projects/addon/info?${j.param({ projectId })}`);
  }

  static async createNewProject(values) {
    return await j.postJSON(`/spr/projects`, values);
  }

  static async findAllUsers(projectId) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/all-users`);
    return data;
  }

  static async changeUserRole(projectId, userId, role) {
    const { data } = await Axios.put(`/spr/projects/${projectId}/change-role`, { userId, role });
    return data;
  }

  static async removeAccess(projectId, userId) {
    const { data } = await Axios.delete(`/spr/projects/${projectId}/users/${userId}`);
    return data;
  }

  static async removeAccesses(projectId = [], userId = []) {
    await Axios.delete(`/spr/projects/users`, { params: { projectId, userId } });
  }

  static async inviteUsers(usersToInvite = [], projects = [], role = 'Viewer') {
    const { data } = await Axios.post(`/spr/projects/users/invite`, {
      usersToInvite,
      projects,
      role,
    });
    return data;
  }

  static async resendUserInvite(projectId, invitedUserId) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/resend-invite/${invitedUserId}`);
    return data;
  }

  static async inviteUserIfExists(projectId, email) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/user-create`, {
      email,
    });
    return data;
  }

  static async findProjectUsersAndGroups(projectId, path = undefined) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/project-users`, {
      params: {
        path,
      },
    });
    return data;
  }

  static findUsers({ projectId = 0, maxResults = 10, initial = 0 }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/members`, { maxResults, initial, type: 'USERS' }));
  }

  static findGroups({ projectId = 0, maxResults = 10, initial = 0 }) {
    return Promise.resolve($.getJSON(`/spr/projects/${projectId}/members`, { maxResults, initial, type: 'GROUPS' }));
  }

  static async findAvailableMembers(projectId, userId) {
    return await j.getJSON(`/spr/projects/${projectId}/available-members/${userId}`);
  }

  static findLastUpdate(path) {
    return Promise.resolve(j.getJSON(`/spr/projects/last-update?${j.param({ path })}`));
  }

  static getUserMobile(project) {
    return Promise.resolve(j.getJSON(`/spr/projects/mobileusers`, { project }));
  }

  static saveMobileUsers(projectId, users) {
    return Promise.resolve(j.postJSON(`/spr/projects/savemobileusers`, { projectId, users }));
  }

  static getMobileEnable(iduser, idproject) {
    return Promise.resolve(j.getJSON(`/spr/projects/getenablemobile`, { iduser, idproject }));
  }

  static async findSourceObjectCount(projectId, sourceName) {
    return await Axios.getData(`/spr/projects/${projectId}/${sourceName}/objectCount`);
  }

  static findPermission(path) {
    return Promise.resolve(j.getJSON(`/spr/projects/find-permission?${j.param({ path })}`));
  }

  static updatePermission(permission) {
    return Promise.resolve(j.postJSON(`/spr/projects/update-permission`, permission));
  }

  static async findTheme(projectId) {
    let promise = THEME_CACHE[projectId];
    if (!promise) {
      promise = Axios.get(`/spr/projects/${projectId}/theme`);
      THEME_CACHE[projectId] = promise;
    }
    const { data } = await promise;
    return data;
  }

  static async createFolder({ projectId, parentFolder, name, icon, oldPath }) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/folders`, { parentFolder, name, icon, oldPath });
    BimEventBus.emit(ProjectApi.FOLDER_CREATED_EVENT, { projectId, parentFolder, name, icon, oldPath });
    return data;
  }

  static async updateFolderIcon({ projectId, folder, icon }) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/folders/icon`, { folder, icon });
    return data;
  }

  static async removePath({ projectId, path }) {
    const { data } = await Axios.delete(`/spr/projects/${projectId}/removePath`, { params: { path } });
    return data;
  }

  static async addUser({ projectId, userId, permission = 'Viewer' }) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/users`, { userId, permission });
    return data;
  }

  static async findStructures(projectId, params = { withDetails: false }) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/origins`, {
      params,
    });
    return data.map((o) => new StructureUi(o));
  }

  static async findResumedStructures(projectId, params = {}) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/origins-for-structure-page`, {
      params,
    });
    return data.map((o) => new ResumedStructureUi(o));
  }

  static async findProjectPlans() {
    // Pegando de um arquivo até o serviço de billing ficar pronto
    const { data } = await Axios.get('/resources/plans.json');
    return data;
  }

  static async requestActivation(projectId, values = {}) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/activate`, values);
    return data;
  }

  static async hasDeleteSchedules(projectId) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/delete-schedules`);
    return data;
  }

  static async scheduleProjectRemoval(projectId) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/schedule-removal`);
    return data;
  }

  static async findUsersCockpitConfig(projectId) {
    const { data } = await Axios.get(`/spr/projects/${projectId}/users/cockpitConfigs`);
    return data;
  }

  static async saveUsersCockpitConfig(projectId, userCockpitConfigs) {
    const { data } = await Axios.post(`/spr/projects/${projectId}/users/cockpitConfigs`, userCockpitConfigs);
    return data;
  }

  static async findLdapUsers(projectId) {
    return await Axios.getData(`/spr/projects/${projectId}/ldap-users`);
  }

  static async updateLdapUsers(projectId, ldapUserList = []) {
    return await Axios.postData(`/spr/projects/${projectId}/ldap-users`, ldapUserList);
  }
}

export default ProjectApi;

class StructureUi {
  constructor(props) {
    Object.assign(this, props);
    this.createdAt = this.createdAt ? moment(this.createdAt) : this.createdAt;
    this.updatedAt = this.updatedAt ? moment(this.updatedAt) : this.updatedAt;
    this.dataUpdatedAt = this.dataUpdatedAt ? moment(this.dataUpdatedAt) : this.dataUpdatedAt;
  }

  get allowExplore() {
    return this.type === 'ANALYTICAL';
  }

  get successfullyLoadedOnce() {
    return this.tableSize > 0;
  }
}
