import React from 'react';
import useTranslation from 'components/hooks/useTranslation';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import AdaAiChat from 'components/bng/adaAi/AdaAiChat';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';

export default function DashboardItemMenuAdaAiInsights({ item, dashboard, assistantKey }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  return (
    <li
      className="DashboardItemMenuAdaAiInsights"
      onClick={() => {
        dispatch(
          MODALS.open(AdaAiChat, {
            initialAssistantKey: assistantKey,
            path: dashboard,
            item,
          })
        );
      }}
    >
      <DashboardItemMenuTitle
        title={t('ada.ai.dashboard.item.menu.title')}
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon-insisghts.png')} alt="Ada AI" />}
        more={false}
      />
    </li>
  );
}
