import styles from './AdaAiButton.module.css';

import React from 'react';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import AdaAiChat from 'components/bng/adaAi/AdaAiChat';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';

export default function AdaAiButton({ item, path, filterProvider, assistantKey }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  return (
    <div className={`AdaAiButton ${styles.AdaAiButton}`}>
      <BngIconButton
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI" />}
        className={`AdaAi ${styles.AdaAi}`}
        text={t('sql.ada.ia.query.assistant.button')}
        onClick={() => {
          dispatch(
            MODALS.open(AdaAiChat, {
              initialAssistantKey: assistantKey,
              path,
              item,
              filters: filterProvider(),
            })
          );
        }}
      />
    </div>
  );
}
