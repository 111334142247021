import { Axios } from 'commonUtils';

const endpoint = '/spr/ada/ai';

class AdaAiApi {
  async findAll(assistantKey) {
    return await Axios.getData(endpoint, {
      params: {
        assistantKey,
      },
    });
  }

  async save(assistant) {
    return await Axios.postData(endpoint, assistant);
  }

  async remove(id = 0) {
    return await Axios.delete(`${endpoint}/${id}`);
  }

  sendMessage = async (
    messageData = {
      message: '',
      metadata: {},
      accountId: 0,
      assistantId: '',
      threadId: '',
      userId: 0,
      name: '',
      description: '',
      instruction: '',
      model: '',
      type: '',
      temperature: '',
      fileId: '',
      runAssistantId: '',
    }
  ) => {
    return await Axios.postData(`${endpoint}/message`, messageData);
  };

  fetchMessageResult = async (params = { accountId: 0, threadId: '', runAssistantId: '' }) => {
    return await Axios.getData(`${endpoint}/message`, {
      params,
    });
  };

  fetchMessages = async (params = { accountId: 0, threadId: '' }) => {
    return await Axios.getData(`${endpoint}/messages`, {
      params,
    });
  };
}

export default AdaAiApi;
