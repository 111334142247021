import styles from './AdaAiChat.module.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FastField, Form, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import useBimContext from 'components/hooks/useBimContext';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import { BngPanelFooter } from 'components/bng/ui/BngPanel';
import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import { bngYup } from 'components/bng/form/yup/BngYup';
import CodeBlock from 'components/bng/adaAi/CodeBlock';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { sessionStore } from 'components/Storage';
import { DIALOG_MODE } from 'components/ui/Dialog';

const STORE_KEY = 'AdaAiChat';
const STORE_TAB_ACTIVE_KEY = `${STORE_KEY}:tabActive`;
const STORE_CHAT_HISTORY_KEY = `${STORE_KEY}:chatHistory`;
const STORE_CHAT_IDS_KEY = `${STORE_KEY}:chatIds`;

const USER_TYPE = {
  ASSISTANT: 'assistant',
  USER: 'user',
};

const WITH_METADATA = ['Assistant-Insights-Objects', 'Assistant-Insights-Dashboard'];
const ASSISTANT_BIM_QUERY = 'Assistant-BIMQuery-SQL';

const $ongoingChatRef = { current: null };

const MsgFormSchema = bngYup((yup) => {
  return yup.object({
    message: yup.string().required().min(1).max(4096).trim().default(''),
  });
});

function TypingDots() {
  return (
    <div className={`TypingDots ${styles.TypingDots}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

function AdaAiChatMessage({ invert = false, avatar = '', name = '', message = '', typing = false, isMaximized }) {
  const isCodeMessage = name === USER_TYPE.ASSISTANT;

  return (
    <div className={`AdaAiChatMessage ${invert ? styles.AdaAiChatMessageInverter : styles.AdaAiChatMessage}`}>
      <div className={`AdaAiChatMessageArrow ${styles.AdaAiChatMessageArrow}`}></div>
      <div className={`AdaAiChatMessageAvatar ${styles.AdaAiChatMessageAvatar}`}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div
        className={`AdaAiChatMessageMessage ${
          isMaximized ? styles.AdaAiChatMessageMessage : styles.AdaAiChatMessageNotMaximized
        }`}
      >
        {typing ? (
          <TypingDots />
        ) : isCodeMessage ? (
          <CodeBlock message={message} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </div>
  );
}

const findTranslated = (context, translatedItem = {}) => {
  const DEFAULT_LOCALE = 'pt_BR';

  const translations = translatedItem?.translations ?? {};
  return (
    translations[context?.user?.preferredLanguage] ?? translations[DEFAULT_LOCALE] ?? Object.values(translations)[0]
  );
};

const assistantConfig = {
  titleSuffix: (metadataObject) => {
    if (!metadataObject) return '';

    const { dashboardItems, dashboardTitle, description } = metadataObject;
    if (dashboardItems?.length === 1) {
      return ` - ${dashboardItems.map((item) => item.name).join(', ')}`;
    }
    if (dashboardTitle || description) {
      return ` - ${dashboardTitle || description}`;
    }
    return '';
  },
};

function groupMessageInSequence(messages, buildUserDetails) {
  const groupedMessages = [];
  let currentUser = null;
  let currentMessageGroup = [];

  messages.forEach((message) => {
    if (_.isEmpty(message.user) || _.isEmpty(message.message)) {
      return;
    }
    if (currentUser === null || message.user !== currentUser) {
      if (currentMessageGroup.length > 0) {
        groupedMessages.push({
          user: buildUserDetails(currentUser),
          message: [...new Set(currentMessageGroup)].join('\n\n'),
        });
      }

      currentUser = message.user;
      currentMessageGroup = [message.message];
    } else {
      if (!currentMessageGroup.includes(message.message)) {
        currentMessageGroup.push(message.message);
      }
    }
  });

  if (currentMessageGroup.length > 0) {
    groupedMessages.push({
      user: buildUserDetails(currentUser),
      message: [...new Set(currentMessageGroup)].join('\n\n'),
    });
  }

  return groupedMessages;
}

const saveAdaAiChatHistory = ({ assistantData, metadataObject, assistantId, threadId, fileId, ongoingChats }) => {
  let adaAiChatHistory = sessionStore.get(STORE_CHAT_HISTORY_KEY) || [];
  const metadataObjectItems = metadataObject?.dashboardItems ?? metadataObject;
  const path = metadataObject?.path || ongoingChats?.path || '';
  const ids = Array.isArray(metadataObjectItems)
    ? metadataObjectItems.map((item) => item.id) || ongoingChats?.id
    : metadataObjectItems?.id
    ? [metadataObjectItems.id]
    : [];

  const newItem = {
    assistantData: assistantData,
    path: path,
    id: ids,
    assistantId: assistantId,
    threadId: threadId,
    fileId: fileId,
  };

  const duplicateIndex = adaAiChatHistory.findIndex(
    (item) =>
      item.assistantData.assistantKey === newItem.assistantData.assistantKey &&
      item.path === newItem.path &&
      _.isEqual(item.id, newItem.id)
  );

  if (duplicateIndex !== -1) {
    const existingItem = adaAiChatHistory[duplicateIndex];
    if (
      existingItem.assistantId !== newItem.assistantId ||
      existingItem.threadId !== newItem.threadId ||
      existingItem.fileId !== newItem.fileId
    ) {
      adaAiChatHistory[duplicateIndex] = newItem;
    }
  } else {
    adaAiChatHistory.push(newItem);
  }
  sessionStore.put(STORE_CHAT_HISTORY_KEY, adaAiChatHistory);
};

const retrieveChatIds = ({ metadata, initialMetadataObject }) => {
  const historyChatIds = sessionStore.get(STORE_CHAT_IDS_KEY);
  if (_.isEmpty(historyChatIds)) {
    return null;
  }

  const metadataChats = metadata?.dashboardItems || initialMetadataObject?.dashboardItems;
  if (metadataChats) {
    for (const historyChatId of historyChatIds) {
      const matchIds = metadataChats.some((dashboardItem) => historyChatId.id.includes(dashboardItem.id));

      if (matchIds) {
        return historyChatId;
      }
    }
  }

  return null;
};

const retrieveChatHistory = async ({ initialAssistantKey, metadataObject, path, accountId }) => {
  const historyMessages = sessionStore.get(STORE_CHAT_HISTORY_KEY);
  if (_.isEmpty(historyMessages)) {
    return null;
  }

  let matchedItem = null;

  if (metadataObject?.dashboardItems) {
    for (const historyMessage of historyMessages) {
      const matchPath = historyMessage.path === metadataObject.path;
      const matchIds = metadataObject.dashboardItems.some((dashboardItem) =>
        historyMessage.id.includes(dashboardItem.id)
      );
      if (matchPath && matchIds) {
        matchedItem = historyMessage;
        break;
      }
    }
  } else {
    for (const historyMessage of historyMessages) {
      if (historyMessage.assistantData.assistantKey === initialAssistantKey && _.isEmpty(path)) {
        matchedItem = historyMessage;
        break;
      }
    }
  }

  if (matchedItem) {
    const messageRecovered = await Api.AdaAi.fetchMessages({
      accountId,
      threadId: matchedItem.threadId,
    });

    return {
      messages: messageRecovered.messages.reverse(),
      assistantId: matchedItem.assistantId,
      threadId: matchedItem.threadId,
    };
  }

  return null;
};

function AdaAiChatTitleText({ metadataObject }) {
  const { t } = useTranslation();
  const titleSuffix = assistantConfig.titleSuffix(metadataObject) || '';
  const fullTitle = `${t('ada.ai.dialog.title')}${titleSuffix}`;

  return (
    <div className={`AdaAiChatTitleText ${styles.AdaAiChatTitleText}`} title={fullTitle}>
      {fullTitle}
    </div>
  );
}

const saveChatId = ({ metadataObject, values, initialMetadataObject }) => {
  const chatIds = sessionStore.get(STORE_CHAT_IDS_KEY) || [];
  const newChatIds =
    metadataObject?.dashboardItems?.map((item) => item.id) ||
    initialMetadataObject?.dashboardItems?.map((item) => item.id) ||
    [];

  const newChat = {
    id: newChatIds,
    path: metadataObject?.path || '',
    values: {
      message: values.message,
      user: USER_TYPE.USER,
    },
  };

  const duplicateIndex = chatIds.findIndex((item) => item.id === newChat.id);

  if (duplicateIndex !== -1) {
    chatIds[duplicateIndex] = newChat;
  } else {
    chatIds.push(newChat);
  }

  sessionStore.put(STORE_CHAT_IDS_KEY, chatIds);
};

export default function AdaAiChat({
  closeModal = _.noop,
  initialMessage,
  initialAssistantKey,
  initialMetadataObject,
  item,
  filters,
  path,
}) {
  const { t } = useTranslation();
  const context = useBimContext();

  const $formikRef = useRef();
  const $messagesEndRef = useRef();
  const $tutorialApi = useRef();

  const [messages, setMessages] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isAdaTyping, setIsAdaTyping] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [threadId, setThreadId] = useState('');
  const [assistantData, setAssistantData] = useState({});
  const [fileId, setFileId] = useState('');
  const [dialogMode, setDialogMode] = useState('');
  const [metadataObject, setMetadataObject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const isMaximized = dialogMode === DIALOG_MODE.MAXIMIZED;
  const isMinimized = dialogMode === DIALOG_MODE.MINIMIZED;

  const buildUserDetails = useCallback((user) => {
    if (user === USER_TYPE.ASSISTANT) {
      return {
        displayName: USER_TYPE.ASSISTANT,
        avatar: Api.buildUrl('/resources/images/ada-ai/ada.png'),
      };
    } else if (user === USER_TYPE.USER) {
      return context.user;
    }
    return user;
  }, []);

  useAsyncEffect({
    onMount: async () => {
      const initializeAssistant = async (initialAssistantKey) => {
        try {
          const assistants = await Api.AdaAi.findAll();
          const assistant = assistants.find((assistant) => assistant.assistantKey === initialAssistantKey);
          setAssistantData(assistant);
          return assistant;
        } catch (e) {
          console.error('Error while trying to find assistantKey', e);
          UiMsg.ajaxError(t('ada.ai.chat.trying.to.find.assistance.error'), e);
        }
      };

      const assistant = await initializeAssistant(initialAssistantKey);
      let metadata = {};
      if (WITH_METADATA.includes(assistant.assistantKey) && !_.isEmpty(path)) {
        const fetchMetadata = async (assistant, path, item, filters) => {
          try {
            setIsLoading(true);
            return await Api.Metadata.exportMetadata({
              content: path,
              filters: item?.filters || filters,
              itemId: item?.id,
              queryResultLimit: assistant.props?.queryResultLimit,
              params: {},
            });
          } catch (e) {
            console.error('Error while trying to get the metadata', e);
            UiMsg.ajaxError(t('ada.ai.get.the.metadata.error'), e);
            return null;
          } finally {
            setIsLoading(false);
          }
        };
        metadata = await fetchMetadata(assistant, path, item, filters);
        setMetadataObject(metadata);
      } else if (initialMetadataObject) {
        setMetadataObject(initialMetadataObject);
      }

      const fetchHistoryChat = async (initialAssistantKey, metadata) => {
        try {
          const history = await retrieveChatHistory({
            initialAssistantKey: initialAssistantKey,
            metadataObject: metadata,
            path,
            accountId: context.accountId,
          });

          if (history) {
            setThreadId(history.threadId);
            setAssistantId(history.assistantId);
            setFileId(history.fileId);

            const groupedMessages = groupMessageInSequence(history.messages, buildUserDetails);
            registerMessage(groupedMessages);
            return history;
          }
        } catch (e) {
          console.error('Error on retrieveChatHistory()', e);
          UiMsg.ajaxError(t('ada.ai.get.the.retrieve.messages.error'), e);
        }
      };

      const history = await fetchHistoryChat(initialAssistantKey, metadata);

      const fetchOngoingChats = async (metadata, initialMetadataObject) => {
        try {
          const ongoingChats = retrieveChatIds({ metadata, initialMetadataObject });
          if (ongoingChats) {
            setCurrentStep(1);
            setIsAdaTyping(true);

            await onSendMessage(ongoingChats.values, assistant, history, ongoingChats);
          }
        } catch (e) {
          console.error('Error on AdaAiChat.retrieveChat()', e);
          UiMsg.ajaxError(t('ada.ai.retrieve.chats.ongoing.error'), e);
        }
      };

      if ($ongoingChatRef.current) {
        await fetchOngoingChats(metadata, initialMetadataObject);
      }

      if (initialMessage) {
        await onSendMessage(initialMessage, assistant, history);
      }
    },
  });

  useEffect(() => {
    const handleScroll = async () => {
      if (isMinimized) {
        return;
      }
      await Utils.sleep(1000);
      $messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    handleScroll();
  }, [initialMessage, currentStep, messages, isAdaTyping, dialogMode]);

  useEffect(() => {
    $tutorialApi.current?.changeStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    sessionStore.put(STORE_TAB_ACTIVE_KEY, true);

    const handleBeforeUnload = () => {
      const isTabReloading = sessionStore.get(STORE_TAB_ACTIVE_KEY) === true;

      if (!isTabReloading) {
        sessionStore.remove(STORE_CHAT_HISTORY_KEY);
      }
      sessionStore.remove(STORE_TAB_ACTIVE_KEY);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (currentStep === 0 && (!_.isEmpty(messages) || !_.isEmpty(initialMessage))) {
      setCurrentStep(1);
    }
  }, [messages, initialMessage, currentStep]);

  const registerMessage = (groupedMessages) => {
    setMessages((messages) => [
      ...messages,
      ...groupedMessages.map((group) => ({
        message: group.message,
        user: group.user,
      })),
    ]);
  };

  const onSendMessage = async (values, assistant, history, ongoingChats) => {
    if (_.isEmpty(values.message)) {
      return;
    }

    if (!ongoingChats) {
      saveChatId({ metadataObject, values, initialMetadataObject });
    }

    if (!history || initialMessage) {
      registerMessage([
        {
          message: values.message,
          user: buildUserDetails(USER_TYPE.USER),
        },
      ]);
    }

    $formikRef.current?.resetForm();

    try {
      setIsAdaTyping(true);

      const sendMessageToAssistant = async (values, assistant, history) => {
        try {
          const responseMessage = await Api.AdaAi.sendMessage({
            message: values.messageGpt || values.message,
            metadata: metadataObject,
            accountId: context.accountId,
            assistantId: assistantId || history?.assistantId,
            threadId: threadId || history?.threadId,
            userId: context.user.id,
            name: assistantData?.name || assistant?.name,
            description: assistantData?.description || assistant?.description,
            instructions: assistantData?.instruction || assistant?.instruction,
            model: assistantData?.model || assistant?.model,
            type: assistantData?.type || assistant?.type,
            temperature: assistantData?.temperature || assistant?.temperature,
            fileId: fileId,
          });

          const fetchAssistantMessageResult = async (responseMessage) => {
            try {
              let messageResult;
              for (let i = 0; i < 60; i++) {
                messageResult = await Api.AdaAi.fetchMessageResult({
                  accountId: context.accountId,
                  threadId: responseMessage.threadId,
                  runAssistantId: responseMessage.runAssistantId,
                });

                if (messageResult.finished) {
                  return messageResult.data;
                }
                await Utils.sleep(1000);
              }
              return null;
            } catch (e) {
              console.error('Error fetching assistant message result', e);
              throw e;
            }
          };

          const messageResult = await fetchAssistantMessageResult(responseMessage);
          if (!messageResult) {
            UiMsg.warn(t('ada.ai.chat.response.timeout.message.warning'));
            registerMessage([
              {
                message: t('ada.ai.chat.response.timeout.message'),
                user: buildUserDetails(USER_TYPE.ASSISTANT),
              },
            ]);
            return null;
          }

          return {
            responseMessage,
            messageResult,
          };
        } catch (e) {
          console.error('Error sending messages to assistant', e);
          throw e;
        }
      };

      if (!ongoingChats) {
        $ongoingChatRef.current = sendMessageToAssistant(values, assistant, history);
      }

      const result = await $ongoingChatRef.current;
      if (result) {
        const groupedMessageResult = groupMessageInSequence(
          [
            {
              message: result?.messageResult,
              user: buildUserDetails(USER_TYPE.ASSISTANT),
            },
          ],
          buildUserDetails
        );
        registerMessage(groupedMessageResult);
        setAssistantId(result?.responseMessage.assistantId);
        setThreadId(result?.responseMessage.threadId);
        setFileId(result?.responseMessage.fileId);

        saveAdaAiChatHistory({
          assistantData: assistant || assistantData,
          metadataObject: metadataObject || initialMetadataObject,
          assistantId: result?.responseMessage.assistantId,
          threadId: result?.responseMessage.threadId,
          fileId: result?.responseMessage.fileId,
          ongoingChats: ongoingChats,
        });
      }
    } catch (e) {
      console.error('Error on onSendMessage()', e);
      UiMsg.ajaxError(t('ada.ai.chat.send.message.to.ada.ai.service.error'), e);
      registerMessage([
        {
          message: t('ada.ai.chat.response.timeout.message'),
          user: buildUserDetails(USER_TYPE.ASSISTANT),
        },
      ]);
    } finally {
      setIsAdaTyping(false);
      sessionStore.remove(STORE_CHAT_IDS_KEY);
      $ongoingChatRef.current = null;
    }
  };

  const steps = useMemo(() => {
    const VALIDATION_ASSISTANT =
      assistantData?.assistantKey !== ASSISTANT_BIM_QUERY && (_.isNil(path) || _.isNil(metadataObject));
    return [
      {
        title: () => (
          <div className={`AdaAiChat ${styles.AdaAiChat}`}>
            <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI Icon" />
            <AdaAiChatTitleText metadataObject={metadataObject} />
          </div>
        ),
        hideClose: false,
        render: ({ changeToNextStep }) => {
          return (
            <UiBlocker block={isLoading}>
              <div className={`StepOneWrapper ${styles.StepOneWrapper}`}>
                {assistantData?.props?.phrase?.map((translatedPhrase, index) => {
                  const phraseData = findTranslated(context, translatedPhrase);
                  return (
                    <div key={index}>
                      <img
                        src={Api.buildUrl('/resources/images/ada-ai/ada-ai-acc.png')}
                        alt="Ada Ai Dialog"
                        className={`AdaAiChatBodyImg ${styles.AdaAiChatBodyImg}`}
                      />
                      <h1>{t('ada.ai.dialog.body.title')}</h1>
                      <h2>{phraseData.introductory}</h2>
                      <h3>{phraseData.benefits}</h3>
                      {VALIDATION_ASSISTANT ? (
                        <div className={`AdaAiChatMessageInfo ${styles.AdaAiChatMessageInfo}`}>
                          <Icon icon="info" />
                          <h3 className={`AdaAiChatMessageInfoH3 ${styles.AdaAiChatMessageInfoH3}`}>
                            {context.msg.t('ada.ai.chat.when.is.no.there.metadata.in.the.dashboard')}
                          </h3>
                        </div>
                      ) : (
                        <h3 className={`Question ${styles.Question}`}>{phraseData.question}</h3>
                      )}
                    </div>
                  );
                })}
                <div className={`AdaAiChatMessageButton ${styles.AdaAiChatMessageButton}`}>
                  {assistantData?.props?.questions?.map((translatedQuestion, index) => {
                    if (VALIDATION_ASSISTANT) {
                      return null;
                    }
                    const questionData = findTranslated(context, translatedQuestion);
                    return (
                      <BngIconButton
                        key={index}
                        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-idea.png')} alt="Ada Ai" />}
                        className={`AdaAiChatBodyButton ${styles.AdaAiChatBodyButton}`}
                        text={questionData.caption}
                        onClick={() => {
                          changeToNextStep();
                          if (currentStep !== 0) {
                            return;
                          }
                          setCurrentStep(1);
                          onSendMessage({
                            message: questionData.question,
                            user: {
                              displayName: context.user.displayName,
                              avatar: context.user.avatarLink,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </UiBlocker>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
      {
        title: ({ changeToPreviousStep }) => (
          <div className={`AdaAiChatTilte ${styles.AdaAiChatTitle}`}>
            <BngIconButton
              icon="chevron_left"
              onClick={changeToPreviousStep}
              className={`BackArrow ${styles.BackArrow}`}
              disabled={isMinimized || isAdaTyping}
            />
            <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI Icon" />
            <AdaAiChatTitleText metadataObject={metadataObject} />
          </div>
        ),
        hideClose: false,
        render: () => {
          return (
            <div className={`AdaAiChatWrapper ${styles.AdaAiChatWrapper}`}>
              <div className={`AdaAiChatHistoryWrapper ${styles.AdaAiChatHistoryWrapper}`}>
                {messages.map((message, idx) => {
                  return (
                    <AdaAiChatMessage
                      key={`Message-${idx}`}
                      name={message.user.displayName}
                      avatar={message.user.avatarLink || Api.buildUrl('/resources/images/ada-ai/ada.png')}
                      invert={message.user.displayName !== USER_TYPE.ASSISTANT}
                      message={message.messageGpt || message.message}
                      isMaximized={isMaximized}
                    />
                  );
                })}
                {isAdaTyping && (
                  <AdaAiChatMessage
                    name={t('sql.ada.ai.query.assistant.displayName')}
                    avatar={Api.buildUrl('/resources/images/ada-ai/ada.png')}
                    invert={false}
                    typing={true}
                  />
                )}
                <div ref={$messagesEndRef} />
              </div>
              <BngPanelFooter className={`BngPanelFooter ${styles.BngPanelFooter}`}>
                <Formik
                  initialValues={MsgFormSchema.default()}
                  onSubmit={onSendMessage}
                  innerRef={$formikRef}
                  validationSchema={MsgFormSchema}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form
                        className={isAdaTyping ? `FooterAdaAiChat ${styles.FooterAdaAiChat}` : ''}
                        autoComplete="off"
                        style={{ display: 'flex' }}
                      >
                        <FastField
                          className={`MessageInput ${styles.MessageInput}`}
                          name="message"
                          placeholder={t('your.answer')}
                          maxLength={4096}
                          style={{ maxHeight: 60, overflowY: 'hidden' }}
                          onKeyDown={async (e) => {
                            if (e.keyCode !== 13) {
                              return;
                            }

                            if (e.altKey) {
                              setFieldValue('message', values.message + '\r\n');
                            } else {
                              onSendMessage(values);
                              e.target.blur();
                            }
                          }}
                        />
                        <BngIconButton className="Send" type="submit" icon="send" title={t('send.comment')} />
                      </Form>
                    );
                  }}
                </Formik>
              </BngPanelFooter>
            </div>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
    ];
  }, [messages, isAdaTyping, currentStep, assistantData, initialMessage, dialogMode, metadataObject, isLoading]);

  return (
    <BngStepTutorialDialog
      enableDotNavigation={false}
      renderDefaultFooter={false}
      steps={steps}
      newDialogLayout={true}
      className={styles.AdaAiChat}
      onClose={closeModal}
      onApi={(api) => ($tutorialApi.current = api)}
      showMaximize={true}
      showMinimize={true}
      onModeChanged={setDialogMode}
      backdropClassName={`AdaAiModalBackdrop ${styles.AdaAiModalBackdrop}`}
    />
  );
}
